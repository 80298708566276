<template>
  <transition name="dialog">
    <div v-if="show" class="fixed inset-0 z-40 flex items-end">
      <div class="absolute right-2 top-2 z-40" @click="onClickBackground">
        <IconClose class="w-8 h-8 fill-current text-white" />
      </div>
      <div class="absolute inset-0 bg-black" @click="onClickBackground" />
      <div class="content relative m-auto overflow-hidden rounded-sm shadow-xl">
        <div class="slider h-full w-full max-w-[440px]">
          <div
            :key="index"
            v-for="(url, index) in images"
            class="slider-item"
            @click="onClickBackground"
          >
            <ImageSource :src="url" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts">
export default defineComponent({
  props: {
    images: {
      default: () => [],
      type: Array as PropType<string[]>,
    },
  },
  data() {
    return {
      show: false,
    }
  },
  mounted() {
    this.show = true
    document.addEventListener('keydown', this.onKeydown)
    if (document.activeElement) {
      ;(document.activeElement as HTMLElement).blur()
    }
  },
  beforeMounted() {
    document.removeEventListener('keydown', this.onKeydown)
  },
  methods: {
    onClickBackground() {
      this.show = false
      this.$emit('close')
    },
    onKeydown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        this.$emit('close')
      }
    },
  },
})
</script>
<style lang="postcss" scoped>
.dialog-enter-active,
.dialog-leave-active {
  transition: opacity 250ms;

  .content {
    transition: all 250ms;
  }
}
.dialog-enter,
.dialog-leave-to {
  @apply opacity-0;
}
.dialog-enter {
  .content {
    @apply opacity-0;

    transform: translateY(12.5%);
  }
}
.dialog-leave-to {
  .content {
    @apply opacity-0;

    transform: translateY(12.5%);
  }
}
.slider {
  @apply flex w-full items-center overflow-x-scroll scrollbar-hide;
  scroll-snap-type: x mandatory;
}

.slider-item {
  @apply relative;
  flex: 0 0 100%;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}
</style>
