<template>
  <LayoutContainer
    class="safe-inset-t safe-inset-b h-full select-none bg-white"
  >
    <slot />

    <template v-if="dialogOptions">
      <DialogConfirm
        v-bind="dialogOptions"
        @confirm="closeDialog(true)"
        @cancel="closeDialog(false)"
        @close="dialogOptions.force ? null : closeDialog(undefined)"
      />
    </template>

    <template v-if="imageViewerOptions">
      <LightBox v-bind="imageViewerOptions" @close="closeImageViewer" />
    </template>

    <template v-if="loading">
      <DialogLoading />
    </template>
  </LayoutContainer>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useDialogStore } from '~/store/dialog'
import { useImageViewerStore } from '~/store/imageViewer'
import { useLoadingStore } from '~/store/loading'

export default defineComponent({
  setup() {
    const dialogStore = useDialogStore()
    const imageViewerStore = useImageViewerStore()
    const loadingStore = useLoadingStore()

    const dialogOptions = computed(() => dialogStore.options)
    const imageViewerOptions = computed(() => imageViewerStore.options)
    const loading = computed(() => loadingStore.opened)

    const closeDialog = (confirmed?: boolean) => {
      dialogStore.close(confirmed)
    }

    const closeImageViewer = () => {
      imageViewerStore.close()
    }

    const closeLoading = () => {
      loadingStore.close()
    }

    return {
      loading,
      dialogOptions,
      imageViewerOptions,
      closeDialog,
      closeImageViewer,
      closeLoading,
    }
  },
})
</script>
